.App {
  text-align: center;
  font-family: 'GT Maru Mono Trial';
  font-size: 14px;
  color: var(--white);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Colours */

:root {
	--white: #fff;
	--dark: #15182b;
	--dark-80: #222641;
	--dark-60: #484a61;
	--dark-40: #646470;
	--dark-20: #7c7e8b;
	--accent: #ffd577;
	--cyan: #03eee7;
	--magenta: #fc0071;
	--pink: #ff429e;
	--blue: #4000d2;

  --quiz-submit-height: 86px;
}

/* Type */
@font-face {
    font-family: 'GT Maru Mono Trial';
    src: url('assets/GTMaruMonoTrial-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Maru Mono Trial';
    src: url('assets/GTMaruMonoTrial-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

input {
    font-family: 'GT Maru Mono Trial';
}

h1, h2, h3,
.text-title {
    font-size: 2rem;
    font-weight: 300;
    text-transform: none;
    letter-spacing: 0.02em;
    margin-bottom: 1rem;
}

.text-logo {
    text-transform: uppercase;
    word-break: break-all;
}

.text-accent {
    color: var(--accent);
}

.text-white {
    color: var(--white);
}

.text-white-50 {
    color: var(--white);
    opacity: 0.4;
}

.text-large {
    font-size: 1.4rem;
}

.text-center {
    text-align: center;
}

p {
    margin: 1rem 0;
}

p:first-of-type {
    margin-top: 0;
}

p:last-of-type {
    margin-bottom: 0;
}

a {
    text-decoration: none;
    color: unset;
}

@media (max-width: 980px){
    html {
        font-size: 10px;
    }
}

/* Structure */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: var(--dark);
}

main {
    position: relative;
    min-height: 100vh;
}

section {
    position: relative;
}

/* Navigation */

.nav-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 1rem;
}

.dashboard-logo {
    width: 75%;
}

/* Logo */

svg#logo {
    fill: var(--white);
    max-width: 80px;
}

.dashboard-logo {
    display: flex;
    align-items: center;
}

.dashboard-logo h1 {
    margin: 0 2rem;
}

/* CTA */

.button-text :hover{
    cursor: pointer;
}

.cta-container {
    margin: 4rem 0;
}

.cta-container p {
    display: inline-block;
    background-color: var(--accent);
    border: 2px var(--accent) solid;
    color: var(--dark);
    text-align: center;
    padding: 2rem min(4rem, 10%);
    border-radius: 4rem;
    min-width: 200px;
    transition: background-color 0.5s ease;
}

.cta-disabled p {
    margin: 4rem 0;
    display: inline-block;
    background-color: var(--dark-20);
    border: 2px var(--dark-20) solid;
    color: var(--dark);
    text-align: center;
    padding: 2rem min(4rem, 10%);
    border-radius: 4rem;
    min-width: 200px;
    transition: background-color 0.5s ease;
}

.cta-container p:hover {
    background-color: rgba(0,0,0,0);
    border-color: var(--white);
    color: var(--white);
    transition: background-color 0.5s ease;
    cursor: pointer;
}

.cta-link p {
    color: var(--accent);
    transition: color 0.5s ease;
}

.cta-link p:hover {
    color: var(--white);
    transition: color 0.5s ease;
    cursor: pointer;
}

@media (max-width: 980px) {
    .cta-container a {
        min-width: unset;
    }
}

/* SVGs */

#wave,
#play,
#pause,
#check,
#circle,
#half-circle,
#clock,
#mini-wave {
    fill: var(--white);
}

#play,
#pause,
#check,
#circle,
#half-circle,
#clock {
    max-width: 25px;
    width: 100%;
}

/* Dividers */

.divider-line {
    height: 2px;
    background-color: var(--white);
    opacity: 0.4;
    margin: 2rem 0;
}

/* Hero Canvas */

canvas#hero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Hero Card */

.hero-card-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-card-container {
    background-color: var(--dark);
    padding: min(4rem, 10%);
    border-radius: 4rem;
    margin: 1rem;
    width: 100%;
    max-width: 450px;
}

.login-logo {
    margin-bottom: min(4rem, 10%);
}

.login-logo svg {
    display: block;
    margin: 0 auto 1rem;
}

.login-message {
    padding: 1rem 0 0;
}

.login-card-container .cta-container {
    margin: 2rem 0 0;
}

.login-card-container .cta-container a {
    color: var(--white);
    background-color: rgba(0,0,0,0);
    border-color: var(--white);

}

.login-card-container .cta-container a:hover {
    color: var(--dark);
    background-color: var(--white);
}

/* Scores */

.answers-container {
    max-width: 220px;
    margin: 0 auto 2rem;
}

.text-score-container {
    background-color: var(--accent);
    border-radius: 50%;
    position: relative;
    padding-top: 100%;
    margin: 2rem auto ;
}

.text-score-container p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 8rem;
    color: var(--dark);
}

/* Form Inputs */

input {
    padding: 1rem;
    border-radius: 2rem;
    display: block;
    width: 100%;
    margin: 1rem 0;
    border-width: 0;
    background-color: var(--white);
}

input:disabled {
  background-color: var(--dark-40);
}

input[type="submit"] {
    background-color: var(--accent);
}

::-webkit-input-placeholder,
::placeholder {
    color: var(--dark);
}

/* Dashboard */

.dashboard-container {
    background-color: var(--dark);
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 2rem;
    padding: 2rem;
    min-height: 100vh;
}

.dashboard-main-container {
    grid-column: span 6;
}

.dashboard-sidebar-container {
    grid-column: span 2;
}

.dashboard-main-container,
.dashboard-sidebar-container {
    display: flex;
    flex-direction: column;
}

.nav {
    margin-bottom: min(4rem, 10%);
}

.dashboard-main,
.dashboard-sidebar {
    padding: min(4rem, 10%);
    border-radius: 4rem;
    background-color: var(--dark-80);
}

.dashboard-main .main-inner {
    margin: 2rem 0;
}

.dashboard-main .meta-container {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.dashboard-main .meta-container .time-container {
    display: flex;
    gap: 1rem;
    align-items: center;
}

/* Quiz */

.quiz-container {
    margin: 4rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.game-list-entry {
    background-color: var(--dark-60);
    color: var(--dark);
    padding: 2rem min(4rem, 10%);
    border-radius: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
}

.game-list-entry:hover{
  background-color: var(--dark-20);
}

.game-list-entry .question-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.game-list-entry.empty {
    background-color: rgba(0,0,0,0);
    border: 2px solid var(--dark-40);
}

.game-list-entry.empty p,
.game-list-entry.empty svg {
    opacity: 0;
}

.game-list-entry .play-container,
.game-list-entry .meta-container  {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.game-list-entry .play-container {
    margin-right: 1rem;
}

.game-list-entry .play-container :hover {
  cursor: pointer;
}

.game-list-entry .mini-wave-container {
    flex-grow: 1;
    max-width: 250px;
    line-height: 0;
}

.game-list-entry .mini-wave-container svg {
    width: 100%;
}

.game-list-entry p {
    margin: 0;
}

.game-list-entry .submit-container {
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin-top: 0;
    padding-top: 0;
    transition: opacity 0.5s ease-in, height 0.5s ease-in;
}

.game-list-entry.active .submit-container {
    padding-top: 1rem;
    border-top: 2px solid var(--dark);
    margin-top: 2rem;
    width: 100%;
    visibility: visible;
    opacity: 1;
    height: var(--quiz-submit-height);
    transition: opacity 0.5s ease-in 0.5s, height 0.5s ease-in;
}

.game-list-entry .submit-container form {
    display: flex;
    gap: 1rem;
}

/* Sidebar */

.wave-container {
    margin: 2rem 0;
}

.dashboard-sidebar {
    position: sticky;
    top: 2rem;
}

.dashboard-sidebar .sidebar-inner {
    margin: 2rem 0;
}

.main-content {
    margin: 2rem 0;
}

.main-content p {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
}

/* Dashboard Media Query */

@media (max-width: 1280px) {

    .dashboard-main-container {
        grid-column: span 5;
    }
    
    .dashboard-sidebar-container {
        grid-column: span 3;
    }

    .game-list-entry .meta-container p {
        display: none;
    }
}

@media (max-width: 980px) {

    .dashboard-logo {
        width: 100%;
    }

    .welcome-message {
        display: none;
    }
    
}

@media (max-width: 640px) {

    .dashboard-main-container,
    .dashboard-sidebar-container  {
        grid-column: span 8;
    }
    
}

/* Footer */

footer {
    display: none;
    visibility: hidden;

    background-color: var(--dark-80);
    padding: min(4rem, 10%);
    border-radius: 4rem 4rem 0 0 ;
    margin: 0 2rem;
}

.footer-inner {
    display: flex;
    justify-content: space-between;
}